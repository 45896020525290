const kamers = [
  [
    {
      hero: "Hildo krop",
      "2personen": 109,
      "3personen": 0,
      copy1: {
        Dutch:
          "Vernoemd naar de in Steenwijk geboren beroemde kunstenaar en beeldhouwer Hildo Krop, de Stadsbeeldhouwer van Amsterdam.",
        German:
          "Benannt nach dem berühmten Künstler und Bildhauer Hildo Krop, dem Stadtbildhauer von Amsterdam, geboren in Steenwijk.",
        Chinese:
          "希尔多·克罗普 (Hildo Krop) 出生于斯廷韦克 (Steenwijk)，是阿姆斯特丹的艺术家和造型师。",
        English:
          "Named after the famous artist and sculptor Hildo Krop, the City Sculptor of Amsterdam, born in Steenwijk.",
        Spanish:
          "Lleva el nombre del famoso artista y escultor Hildo Krop, el escultor de la ciudad de Ámsterdam, nacido en Steenwijk.",
      },

      copy2: {
        Dutch:
          "Deze prachtige, 2-persoons, lichte, kamer met Engels behang, rijke, velourse, gordijnstoffen en zachte vloerbedekking bevind zich op de 2e verdieping van Villa Steenwyck met daardoor een prachtig uitzicht over vestingstad Steenwijk en de Steenwijker toren.",
        German:
          "Dieses schöne, helle Zimmer für 2 Personen mit englischen Tapeten, edlem Samt, Vorhangstoffen und weichen Teppichen befindet sich im 2. Stock der Villa Steenwyck und bietet einen wunderschönen Blick auf die Festungsstadt Steenwijk und den Steenwijkerturm.",
        Chinese:
          "这间美丽的 2 人明亮客房位于 Villa Steenwyck 的二楼，配有英式壁纸、华丽的天鹅绒、窗帘面料和柔软的地毯，可欣赏 Steenwijk 防御城镇和 Steenwijker 塔的美丽景色。",
        English:
          "This beautiful, 2-person, bright room with English wallpaper, rich velvet, curtain fabrics and soft carpeting is located on the 2nd floor of Villa Steenwyck with a beautiful view of the fortified town of Steenwijk and the Steenwijker tower.",
        Spanish:
          "Esta hermosa habitación luminosa para 2 personas con papel tapiz inglés, rico terciopelo, telas para cortinas y alfombras suaves está ubicada en el segundo piso de Villa Steenwyck con una hermosa vista de la ciudad fortificada de Steenwijk y la torre Steenwijker.",
      },
      voorziening: {
        Dutch: [
          "Luxe Boxspring bedden (2 bedden tegen elkaar aan, 180x200cm met luxe topper)",
          "De bedden zijn ook los van elkaar te zetten, als 2 1-persoons bedden van 90x200cm. Indien gewenst",
          "Satijnen beddengoed en badtextiel van “De witte Lietaer”",
          "Eet setje om uw ontbijt te nuttigen of een spelletje te spelen",
          "Eet setje om uw ontbijt te nuttigen of een spelletje te spelen",
          "Luxe Philips Ambilight TV met Netflix en kabel TV",
          "Gratis super snelle wifi",
          "Voorzien van Daikin warmtepomp airconditioners voor het milieu bewust koelen en verwarmen van uw kamer met ingebouwde flash-streamer luchtreiniging tegen stof, pollen, virussen, bacteriën en allergenen, Voor een altijd frisse “berglucht” in uw kamer",
          "Gedeelde badkamer met toilet, bidet, wastafel en luxe regendouche, met per kamer een plankje voor uw toilet spullen",
          "Gratis koffie (Nespresso) en thee faciliteiten",
          "Koelkast met daarin koel, plat water",
          "2 Luxe, zachte badjassen van “De Witte Lietaer”",
        ],
        German: [
          "Luxuriöse Boxspringbetten (2 Betten nebeneinander, 180x200cm mit luxuriöser Topper)",
          "Die Betten können auch einzeln gestellt werden, als 2 Einzelbetten von 90x200cm. Auf Wunsch",
          "Satinschlafwäsche und Badtextilien von 'De Witte Lietaer'",
          "Ess-Set zum Frühstücken oder für Spiele",
          "Ess-Set zum Frühstücken oder für Spiele",
          "Luxuriöser Philips Ambilight Fernseher mit Netflix und Kabel-TV",
          "Kostenloses superschnelles WLAN",
          "Ausgestattet mit Daikin-Wärmepumpenklimaanlagen für umweltbewusstes Kühlen und Heizen Ihres Zimmers mit integrierter Flash-Streamer-Luftreinigung gegen Staub, Pollen, Viren, Bakterien und Allergene, für immer frische 'Bergluft' in Ihrem Zimmer",
          "Gemeinsames Badezimmer mit Toilette, Bidet, Waschbecken und luxuriöser Regendusche, mit einem Regal pro Zimmer für Ihre Toilettenartikel",
          "Kostenloser Kaffee (Nespresso) und Tee",
          "Kühlschrank mit gekühltem, stillem Wasser",
          "2 Luxuriöse, weiche Bademäntel von 'De Witte Lietaer'",
        ],
        Chinese: [
          "豪华弹簧床（两张床并排，180x200厘米，配有豪华床垫）",
          "床也可以单独放置，作为2张90x200厘米的单人床。根据需要",
          "“De Witte Lietaer”的缎面床上用品和浴巾",
          "用于享用早餐或玩游戏的餐具套装",
          "用于享用早餐或玩游戏的餐具套装",
          "带Netflix和有线电视的豪华Philips Ambilight电视",
          "免费超快速WiFi",
          "配备Daikin热泵空调，用于环保冷却和加热您的房间，并配有内置的闪存流式空气净化器，可防止灰尘、花粉、病毒、细菌和过敏原，让您的房间始终充满新鲜的“山区空气”",
          "共用浴室，设有厕所、坐浴盆、洗手盆和豪华淋浴，每间客房配有一块放置卫生用品的架子",
          "免费咖啡（Nespresso）和茶设施",
          "冰箱内备有冷、平静的水",
          "2件“De Witte Lietaer”豪华柔软的浴袍",
        ],
        English: [
          "Luxury Boxspring beds (2 beds together, 180x200cm with luxury topper)",
          "The beds can also be separated, as 2 single beds of 90x200cm each. If desired",
          "Satin bedding and bath textiles from “De Witte Lietaer”",
          "Dining set to enjoy your breakfast or play a game",
          "Dining set to enjoy your breakfast or play a game",
          "Luxury Philips Ambilight TV with Netflix and cable TV",
          "Free super-fast wifi",
          "Equipped with Daikin heat pump air conditioners for environmentally conscious cooling and heating of your room with built-in flash-streamer air purification against dust, pollen, viruses, bacteria, and allergens, For always fresh “mountain air” in your room",
          "Shared bathroom with toilet, bidet, sink, and luxury rain shower, with a shelf for your toilet items per room",
          "Free coffee (Nespresso) and tea facilities",
          "Refrigerator with cool, flat water",
          "2 Luxury, soft bathrobes from “De Witte Lietaer”",
        ],
        Spanish: [
          "Camas de muelles embolsados de lujo (2 camas juntas, 180x200cm con cubrecolchón de lujo)",
          "Las camas también se pueden separar, como 2 camas individuales de 90x200cm cada una. Si se desea",
          "Ropa de cama de satén y textiles de baño de “De Witte Lietaer”",
          "Conjunto de comedor para disfrutar de su desayuno o jugar un juego",
          "Conjunto de comedor para disfrutar de su desayuno o jugar un juego",
          "Televisor Philips Ambilight de lujo con Netflix y televisión por cable",
          "Wifi súper rápido gratuito",
          "Equipado con aires acondicionados de bomba de calor Daikin para enfriar y calentar su habitación de manera ambientalmente consciente con purificación de aire flash-streamer incorporada contra el polvo, polen, virus, bacterias y alérgenos, Para siempre tener aire fresco de “montaña” en su habitación",
          "Baño compartido con inodoro, bidet, lavabo y ducha de lluvia de lujo, con un estante para sus artículos de tocador por habitación",
          "Café gratuito (Nespresso) y facilidades para hacer té",
          "Refrigerador con agua fría y plana",
          "2 batas de baño de lujo y suaves de “De Witte Lietaer”",
        ],
      },
    },
  ],
  [
    {
      hero: "Tromp Meesters",
      "2personen": 119,
      "3personen": 149,
      copy1: {
        Dutch:
          "Vernoemd naar Hendrik Jan Trom Meesters, een rijke, Steenwijkse, ondernemer en filantroop, die o.a. Boutique hotel Villa Steenwijk in 1905, als Wijkverpleging liet bouwen.",
        German:
          "Benannt nach Hendrik Jan Trom Meesters, einem wohlhabenden Unternehmer und Philanthropen aus Steenwijk, der unter anderem das Boutique-Hotel Villa Steenwijk im Jahr 1905 als Wijkverpleging errichten ließ.",
        Chinese:
          "以亨德里克·扬·特罗姆·梅斯特斯（Hendrik Jan Trom Meesters）的名字命名，他是一位富有的斯廷维克企业家和慈善家，于1905年建造了精品酒店维拉·斯廷维克（Boutique hotel Villa Steenwijk），作为维克护理中心之一。",
        English:
          "Named after Hendrik Jan Trom Meesters, a wealthy Steenwijk entrepreneur and philanthropist, who, among other things, had the Boutique hotel Villa Steenwijk built in 1905 as a district nurse.",
        Spanish:
          "Debe su nombre a Hendrik Jan Trom Meesters, un rico empresario y filántropo de Steenwijk que, entre otras cosas, hizo construir en 1905 el hotel boutique Villa Steenwijk como enfermero de distrito.",
      },
      copy2: {
        Dutch:
          "Deze prachtige, 2 of 3-persoons, luxe kamer met gouden behang, zachte vloerbedekking en rijke, velourse, gordijnstoffen bevindt zich op de 2e verdieping van Villa Steenwyck aan de voorkant van de Villa. Er kan een 3e slaapplaats voor u gecreëerd worden, dmv een uitklapbare sofa.",
        German:
          "Dieses wunderschöne, 2- oder 3-Personen-Luxuszimmer mit goldenen Tapeten, weichem Teppichboden und reichhaltigen, samtigen Vorhangstoffen befindet sich im 2. Stock der Villa Steenwyck an der Vorderseite der Villa. Es kann ein dritter Schlafplatz für Sie geschaffen werden, durch ein ausklappbares Sofa.",
        Chinese:
          "这间漂亮的、可容纳2或3人的豪华客房，配有金色壁纸、柔软的地毯和丰富的天鹅绒窗帘，位于维拉·斯廷维克（Villa Steenwyck）的二楼，位于维拉的正面。通过折叠沙发，可以为您提供第三个睡眠空间。",
        English:
          "This beautiful, 2 or 3-person, luxurious room with golden wallpaper, soft carpeting and rich velvet curtain fabrics is located on the 2nd floor of Villa Steenwyck at the front of the Villa. A 3rd sleeping place can be created for you, by means of a fold-out sofa.",
        Spanish:
          "Esta hermosa habitación lujosa para 2 o 3 personas con papel tapiz dorado, alfombras suaves y ricas telas de terciopelo para cortinas está ubicada en el segundo piso de Villa Steenwyck, en la parte delantera de la Villa. Se puede crear una tercera plaza para dormir mediante un sofá plegable.",
      },
      voorziening: {
        Dutch: [
          "Luxe “van der Valk” Kingsize Boxspring bed van Swiss Sense 160X200 cm",
          "Satijnen wit beddengoed en badtextiel van “De witte Lietaer”",
          "Luxe Philips Ambilight TV met Netflix en kabel TV",
          "Gratis super snelle wifi",
          "Gedeelde badkamer met toilet, bidet, wastafel en luxe regendouche",
          "Gratis koffie (Nespresso) en Thee faciliteiten",
          "Koelkast met daarin koel, plat water",
          "Voorzien van Daikin warmtepomp airconditioners voor het milieu bewust koelen en verwarmen van uw kamer met ingebouwde flash-streamer luchtreiniging tegen stof, pollen, virussen, bacteriën en allergenen, Voor een altijd frisse “berglucht” in uw kamer",
          "Gedeelde badkamer met toilet, bidet, wastafel en luxe regendouche, met per kamer een plankje voor uw toilet spullen",
          "2 Luxe, zachte badjassen van “De Witte Lietaer”",
        ],
        German: [
          "Luxuriöses 'van der Valk' Kingsize-Boxspringbett von Swiss Sense 160x200 cm",
          "Satinierte weiße Bettwäsche und Badtextilien von 'De Witte Lietaer'",
          "Luxuriöser Philips Ambilight Fernseher mit Netflix und Kabel-TV",
          "Kostenloses superschnelles WLAN",
          "Gemeinschaftsbad mit Toilette, Bidet, Waschbecken und luxuriöser Regendusche",
          "Kostenlose Kaffee (Nespresso) und Tee-Einrichtungen",
          "Kühlschrank mit gekühltem, stillem Wasser",
          "Ausgestattet mit Daikin-Wärmepumpenklimaanlagen für umweltbewusstes Kühlen und Heizen Ihres Zimmers mit integrierter Flash-Streamer-Luftreinigung gegen Staub, Pollen, Viren, Bakterien und Allergene, für immer frische 'Bergluft' in Ihrem Zimmer",
          "Gemeinschaftsbad mit Toilette, Bidet, Waschbecken und luxuriöser Regendusche, mit einem Regal pro Zimmer für Ihre Toilettenartikel",
          "2 Luxuriöse, weiche Bademäntel von 'De Witte Lietaer'",
        ],
        Chinese: [
          "豪华“van der Valk”品牌的Kingsize Boxspring床，来自Swiss Sense，尺寸160x200厘米",
          "“De Witte Lietaer”品牌的亚麻色床上用品和浴巾",
          "配备Netflix和有线电视的豪华Philips Ambilight电视",
          "免费超快速WiFi",
          "共用浴室，配有厕所、坐浴盆、洗手盆和豪华淋浴",
          "免费咖啡（Nespresso）和茶设施",
          "冰箱内备有冷、平静的水",
          "配备Daikin热泵空调，用于环保冷却和加热您的房间，并配有内置的闪存流式空气净化器，可防止灰尘、花粉、病毒、细菌和过敏原，让您的房间始终充满新鲜的“山区空气”",
          "共用浴室，配有厕所、坐浴盆、洗手盆和豪华淋浴，每间客房配有一块放置卫生用品的架子",
          "2件“De Witte Lietaer”品牌的豪华柔软浴袍",
        ],
        English: [
          "Luxury “van der Valk” Kingsize Boxspring bed from Swiss Sense 160X200 cm",
          "Satin white bedding and bath textiles from “De Witte Lietaer”",
          "Luxury Philips Ambilight TV with Netflix and cable TV",
          "Free super-fast wifi",
          "Shared bathroom with toilet, bidet, sink, and luxury rain shower",
          "Free coffee (Nespresso) and Tea facilities",
          "Refrigerator with cool, flat water",
          "Equipped with Daikin heat pump air conditioners for environmentally conscious cooling and heating of your room with built-in flash-streamer air purification against dust, pollen, viruses, bacteria, and allergens, For always fresh “mountain air” in your room",
          "Shared bathroom with toilet, bidet, sink, and luxury rain shower, with a shelf for your toilet items per room",
          "2 Luxury, soft bathrobes from “De Witte Lietaer”",
        ],
        Spanish: [
          "Cama de muelles embolsados de lujo “van der Valk” Kingsize de Swiss Sense 160X200 cm",
          "Ropa de cama blanca de satén y textiles de baño de “De Witte Lietaer”",
          "Televisor Philips Ambilight de lujo con Netflix y televisión por cable",
          "Wifi súper rápido gratuito",
          "Baño compartido con inodoro, bidet, lavabo y ducha de lluvia de lujo",
          "Café gratuito (Nespresso) y facilidades para hacer té",
          "Refrigerador con agua fría y plana",
          "Equipado con aires acondicionados de bomba de calor Daikin para enfriar y calentar su habitación de manera ambientalmente consciente con purificación de aire flash-streamer incorporada contra el polvo, polen, virus, bacterias y alérgenos, Para siempre tener aire fresco de “montaña” en su habitación",
          "Baño compartido con inodoro, bidet, lavabo y ducha de lluvia de lujo, con un estante para sus artículos de tocador por habitación",
          "2 batas de baño de lujo y suaves de “De Witte Lietaer”",
        ],
      },
    },
  ],
  [
    {
      hero: "Kleibeuker",
      "2personen": 109,
      "3personen": 0,
      copy1: {
        Dutch:
          "Vernoemd naar de zeer vriendelijke kinderarts die vanaf 1950-1980 hier gewerkt heeft.",
        German:
          "Benannt nach dem äußerst freundlichen Kinderarzt, der hier von 1950 bis 1980 gearbeitet hat.",
        Chinese:
          "以非常友好的儿科医生命名，该医生在1950年至1980年期间在这里工作过。",
        English:
          "Named after the very friendly pediatrician who worked here from 1950-1980.",
        Spanish:
          "Lleva el nombre del amable pediatra que trabajó aquí entre 1950 y 1980.",
      },
      copy2: {
        Dutch:
          "Deze prachtige, 2-persoons, luxe kamer met rood/gouden behang, zachte vloerbedekking en rijke, velourse, gordijnstoffen bevind zich op de 2e verdieping van Villa Steenwyck aan de zijkant van de Villa.",
        German:
          "Dieses wunderschöne, luxuriöse Zimmer für 2 Personen mit roter/goldener Tapete, weichem Teppichboden und reichhaltigen, samtigen Vorhangstoffen befindet sich im zweiten Stock der Villa Steenwyck an der Seite der Villa.",
        Chinese:
          "这间漂亮的双人豪华客房设有红色/金色墙纸、柔软地毯和丰富的绒面窗帘布料，位于维拉斯蒂恩维克别墅的二楼，别墅的一侧。",
        English:
          "This beautiful, 2-person, luxurious room with red/gold wallpaper, soft carpeting and rich velvet curtain fabrics is located on the 2nd floor of Villa Steenwyck on the side of the Villa.",
        Spanish:
          "Esta hermosa habitación lujosa para 2 personas con papel tapiz rojo/dorado, alfombras suaves y ricas telas de terciopelo para cortinas está ubicada en el segundo piso de Villa Steenwyck, en el costado de la Villa.",
      },
      voorziening: {
        Dutch: [
          "Luxe “van der Valk” Kingsize Boxspring bed van Swiss Sense 160X200cm",
          "Satijnen wit beddengoed en badtextiel van “De witte Lietaer”",
          "Luxe Philips Ambilight TV met Netflix en kabel TV",
          "Gratis super snelle wifi",
          "Gedeelde badkamer met toilet, bidet, wastafel en luxe regendouche",
          "Gratis koffie (Nespresso) en thee faciliteiten",
          "Koelkast met daarin koel, plat water",
          "Voorzien van Daikin warmtepomp airconditioners voor het milieu bewust koelen en verwarmen van uw kamer met ingebouwde flash-streamer luchtreiniging tegen stof, pollen, virussen, bacteriën en allergenen, Voor een altijd frisse “berglucht” in uw kamer",
          "Gedeelde badkamer met toilet, bidet, wastafel en luxe regendouche, met per kamer een plankje voor uw toilet spullen",
          "2 Luxe, zachte badjassen van “De Witte Lietaer”",
        ],
        German: [
          "Luxuriöses 'van der Valk' Kingsize-Boxspringbett von Swiss Sense 160x200cm",
          "Satinierte weiße Bettwäsche und Badtextilien von 'De Witte Lietaer'",
          "Luxuriöser Philips Ambilight Fernseher mit Netflix und Kabel-TV",
          "Kostenloses superschnelles WLAN",
          "Gemeinschaftsbad mit Toilette, Bidet, Waschbecken und luxuriöser Regendusche",
          "Kostenlose Kaffee (Nespresso) und Tee-Einrichtungen",
          "Kühlschrank mit gekühltem, stillem Wasser",
          "Ausgestattet mit Daikin-Wärmepumpenklimaanlagen für umweltbewusstes Kühlen und Heizen Ihres Zimmers mit integrierter Flash-Streamer-Luftreinigung gegen Staub, Pollen, Viren, Bakterien und Allergene, für immer frische 'Bergluft' in Ihrem Zimmer",
          "Gemeinschaftsbad mit Toilette, Bidet, Waschbecken und luxuriöser Regendusche, mit einem Regal pro Zimmer für Ihre Toilettenartikel",
          "2 Luxuriöse, weiche Bademäntel von 'De Witte Lietaer'",
        ],
        Chinese: [
          "豪华'van der Valk'品牌的Kingsize Boxspring床，来自Swiss Sense，尺寸160x200cm",
          "“De Witte Lietaer”品牌的亚麻色床上用品和浴巾",
          "配备Netflix和有线电视的豪华Philips Ambilight电视",
          "免费超快速WiFi",
          "共用浴室，配有厕所、坐浴盆、洗手盆和豪华淋浴",
          "免费咖啡（Nespresso）和茶设施",
          "冰箱内备有冷、平静的水",
          "配备Daikin热泵空调，用于环保冷却和加热您的房间，并配有内置的闪存流式空气净化器，可防止灰尘、花粉、病毒、细菌和过敏原，让您的房间始终充满新鲜的'山区空气'",
          "共用浴室，配有厕所、坐浴盆、洗手盆和豪华淋浴，每间客房配有一块放置卫生用品的架子",
          "2件'De Witte Lietaer'品牌的豪华柔软浴袍",
        ],
        English: [
          "Luxury 'van der Valk' Kingsize Boxspring bed from Swiss Sense 160X200cm",
          "Satin white bedding and bath textiles from “De Witte Lietaer”",
          "Luxury Philips Ambilight TV with Netflix and cable TV",
          "Free super-fast wifi",
          "Shared bathroom with toilet, bidet, sink, and luxury rain shower",
          "Free coffee (Nespresso) and tea facilities",
          "Refrigerator with cool, flat water",
          "Equipped with Daikin heat pump air conditioners for environmentally conscious cooling and heating of your room with built-in flash-streamer air purification against dust, pollen, viruses, bacteria, and allergens, For always fresh “mountain air” in your room",
          "Shared bathroom with toilet, bidet, sink, and luxury rain shower, with a shelf for your toilet items per room",
          "2 Luxury, soft bathrobes from “De Witte Lietaer”",
        ],
        Spanish: [
          "Cama de muelles embolsados de lujo 'van der Valk' Kingsize de Swiss Sense 160X200cm",
          "Ropa de cama blanca de satén y textiles de baño de “De Witte Lietaer”",
          "Televisor Philips Ambilight de lujo con Netflix y televisión por cable",
          "Wifi súper rápido gratuito",
          "Baño compartido con inodoro, bidet, lavabo y ducha de lluvia de lujo",
          "Café gratuito (Nespresso) y facilidades para hacer té",
          "Refrigerador con agua fría y plana",
          "Equipado con aires acondicionados de bomba de calor Daikin para enfriar y calentar su habitación de manera ambientalmente consciente con purificación de aire flash-streamer incorporada contra el polvo, polen, virus, bacterias y alérgenos, Para siempre tener aire fresco de “montaña” en su habitación",
          "Baño compartido con inodoro, bidet, lavabo y ducha de lluvia de lujo, con un estante para sus artículos de tocador por habitación",
          "2 batas de baño de lujo y suaves de “De Witte Lietaer”",
        ],
      },
    },
  ],
  [
    {
      hero: "Johan Van Den Kornput",
      "2personen": 129,
      "3personen": 0,
      copy1: {
        Dutch:
          "Vernoemd naar Johan van den Kornput, een beroemde Nederlandse militair die in 1580 Steenwijk tegen de Spanjaarden verdedigde tijdens de 80-jarige oorlog.",
        German:
          "Benannt nach Johan van den Kornput, einem berühmten niederländischen Militär, der 1580 Steenwijk während des Achtzigjährigen Krieges gegen die Spanier verteidigte.",
        Chinese:
          "以荷兰著名军人约翰内斯·范登·科恩普特（Johan van den Kornput）命名，他在1580年八十年战争期间曾经捍卫斯特温克（Steenwijk）免受西班牙人的进攻。",
        English:
          "Named after Johan van den Kornput, a famous Dutch soldier who defended Steenwijk against the Spanish in 1580 during the 80 Years War.",
        Spanish:
          "Lleva el nombre de Johan van den Kornput, un famoso soldado holandés que defendió Steenwijk contra los españoles en 1580 durante la Guerra de los 80 Años.",
      },
      copy2: {
        Dutch:
          "Deze romantische, 2-persoons, luxe kamer met antiek hemelbed, zachte vloerbedekking en rijke, velourse, stoffen bevind zich op de 1e verdieping van Villa Steenwyck aan de voorkant van de Villa en heeft zijn eigen, aangrenzende badkamer met wastafel en douche.",
        German:
          "Dieses romantische, luxuriöse Zimmer für 2 Personen mit antikem Himmelbett, weichem Teppichboden und reichhaltigen, samtigen Stoffen befindet sich im ersten Stock der Villa Steenwyck an der Vorderseite der Villa und verfügt über ein eigenes angrenzendes Badezimmer mit Waschbecken und Dusche.",
        Chinese:
          "这间浪漫的双人豪华客房设有古董四柱床、柔软的地毯和丰富的绒面布料，位于维拉斯蒂恩维克别墅的一楼，别墅的前部，配有独立的相连浴室，设有洗手盆和淋浴。",
        English:
          "This romantic, 2-person, luxurious room with an antique four-poster bed, soft carpeting and rich velvet fabrics is located on the 1st floor of Villa Steenwyck at the front of the Villa and has its own adjacent bathroom with sink and shower.",
        Spanish:
          "Esta romántica habitación de lujo para 2 personas con una antigua cama con dosel, suaves alfombras y ricas telas de terciopelo está ubicada en el primer piso de Villa Steenwyck, en la parte delantera de la villa, y tiene su propio baño adyacente con lavabo y ducha.",
      },
      voorziening: {
        Dutch: [
          "Antiek Romantisch Hemelbed (140X200) met heerlijk matras en verduisterende hemelbed gordijnen",
          "Satijnen beddengoed en badtextiel van “De witte Lietaer”",
          "Luxe Philips Ambilight TV met Netflix en kabel TV",
          "Gratis super snelle wifi",
          "Eigen badkamer met daarin douche & wastafel",
          "Privé toilet, gelegen op de overloop",
          "Gratis koffie (Nespresso) en thee faciliteiten",
          "Koelkast met daarin koel, plat water",
          "Voorzien van Daikin warmtepomp airconditioners voor het milieu bewust koelen en verwarmen van uw kamer met ingebouwde flash-streamer luchtreiniging tegen stof, pollen, virussen, bacteriën en allergenen, Voor een altijd frisse “berglucht” in uw kamer",
          "Gedeelde badkamer met toilet, bidet, wastafel en luxe regendouche, met per kamer een plankje voor uw toilet spullen",
          "2 Luxe, zachte badjassen van “De Witte Lietaer”",
        ],
        German: [
          "Antikes romantisches Himmelbett (140x200) mit herrlicher Matratze und verdunkelnden Himmelbettvorhängen",
          "Satinierte Bettwäsche und Badtextilien von “De Witte Lietaer”",
          "Luxuriöser Philips Ambilight Fernseher mit Netflix und Kabel-TV",
          "Kostenloses superschnelles WLAN",
          "Eigenes Badezimmer mit Dusche und Waschbecken",
          "Privates WC auf dem Flur",
          "Kostenlose Kaffee (Nespresso) und Tee-Einrichtungen",
          "Kühlschrank mit gekühltem, stillem Wasser",
          "Ausgestattet mit Daikin-Wärmepumpenklimaanlagen für umweltbewusstes Kühlen und Heizen Ihres Zimmers mit integrierter Flash-Streamer-Luftreinigung gegen Staub, Pollen, Viren, Bakterien und Allergene, für immer frische 'Bergluft' in Ihrem Zimmer",
          "Gemeinschaftsbad mit Toilette, Bidet, Waschbecken und luxuriöser Regendusche, mit einem Regal pro Zimmer für Ihre Toilettenartikel",
          "2 Luxuriöse, weiche Bademäntel von 'De Witte Lietaer'",
        ],
        Chinese: [
          "古董浪漫的四柱床（140X200），配有舒适的床垫和遮光的床帘",
          "“De Witte Lietaer”品牌的亚麻色床上用品和浴巾",
          "配备Netflix和有线电视的豪华Philips Ambilight电视",
          "免费超快速WiFi",
          "带淋浴和洗手盆的独立浴室",
          "私人卫生间，位于过道上",
          "免费咖啡（Nespresso）和茶设施",
          "冰箱内备有冷、平静的水",
          "配备Daikin热泵空调，用于环保冷却和加热您的房间，并配有内置的闪存流式空气净化器，可防止灰尘、花粉、病毒、细菌和过敏原，让您的房间始终充满新鲜的'山区空气'",
          "共用浴室，配有厕所、坐浴盆、洗手盆和豪华淋浴，每间客房配有一块放置卫生用品的架子",
          "2件'De Witte Lietaer'品牌的豪华柔软浴袍",
        ],
        English: [
          "Antique Romantic Canopy Bed (140X200) with a comfortable mattress and blackout canopy curtains",
          "Satin bedding and bath textiles from “De Witte Lietaer”",
          "Luxury Philips Ambilight TV with Netflix and cable TV",
          "Free super-fast wifi",
          "Private bathroom with shower & sink",
          "Private toilet, located on the landing",
          "Free coffee (Nespresso) and tea facilities",
          "Refrigerator with cool, flat water",
          "Equipped with Daikin heat pump air conditioners for environmentally conscious cooling and heating of your room with built-in flash-streamer air purification against dust, pollen, viruses, bacteria, and allergens, For always fresh “mountain air” in your room",
          "Shared bathroom with toilet, bidet, sink, and luxury rain shower, with a shelf for your toilet items per room",
          "2 Luxury, soft bathrobes from “De Witte Lietaer”",
        ],
        Spanish: [
          "Cama con dosel romántica antigua (140X200) con un colchón cómodo y cortinas opacas para el dosel",
          "Ropa de cama de satén y textiles de baño de “De Witte Lietaer”",
          "Televisor Philips Ambilight de lujo con Netflix y televisión por cable",
          "Wifi súper rápido gratuito",
          "Baño privado con ducha y lavabo",
          "Inodoro privado, ubicado en el rellano",
          "Café gratuito (Nespresso) y facilidades para hacer té",
          "Refrigerador con agua fría y plana",
          "Equipado con aires acondicionados de bomba de calor Daikin para enfriar y calentar su habitación de manera ambientalmente consciente con purificación de aire flash-streamer incorporada contra el polvo, polen, virus, bacterias y alérgenos, Para siempre tener aire fresco de “montaña” en su habitación",
          "Baño compartido con inodoro, bidet, lavabo y ducha de lluvia de lujo, con un estante para sus artículos de tocador por habitación",
          "2 batas de baño de lujo y suaves de “De Witte Lietaer”",
        ],
      },
    },
  ],
  [
    {
      hero: "Giethoorn",
      "2personen": 139,
      "3personen": 0,
      copy1: {
        Dutch:
          "Naast Boutique hotel Villa Steenwyck, bevind zich onze nieuwste, modern en licht ingerichte hotelkamer, Kamer 5. Giethoorn.",
        German:
          "Neben dem Boutique-Hotel Villa Steenwyck befindet sich unser neuestes, modern und hell eingerichtetes Hotelzimmer, Zimmer 5, Giethoorn.",
        Chinese:
          "在精品酒店维拉斯蒂恩维克别墅旁边，我们最新的、现代化且明亮装饰的酒店客房，房间5，吉思霍恩。",
        English:
          "Next to Boutique hotel Villa Steenwyck, our newest, modern and brightly furnished hotel room is located, Room 5. Giethoorn.",
        Spanish:
          "Junto al hotel boutique Villa Steenwyck se encuentra nuestra habitación de hotel más nueva, moderna y luminosamente amueblada, la habitación 5. Giethoorn.",
      },
      copy2: "",
      voorziening: {
        Dutch: [
          "Extra bed €25,- (Max 3 personen)",
          "40m2",
          "Maximaal 3 personen",
          "Eigen privé opgang",
          "Alles op de begane grond",
          "Gratis parkeren",
          "Luxe kingsize boxspring bed van 160X200cm- Satijnen wit beddengoed en badtextiel van “De witte Lietaer”",
          "Gratis super snelle WiFi",
          "Privé badkamer met toilet,wastafel en grote inloopdouche",
          "CV Verwarming",
          "Voorzien van bad en bed linnen (handdoeken, bedden zijn opgemaakt)",
          "Zitje om het ontbijt te nuttigen",
          "Koelkast",
          "Waterkoker",
          "Gratis koffie (Nespresso) en thee faciliteiten",
          "Desgewenst verzorgen wij een heerlijk, vers, ontbijt op de kamer voor €20,- per persoon",
          "Een prachtige, ruime kamer met grote badkamer en inloopdouche op de begane grond, met een totale oppervlakte van 40m2 met daarin: Garderobe,Hal, ruime toilet, opbergkast, aparte slaapkamer met een heerlijk 2-persoons bed (160X200), ensuite badkamer met grote inloop douche",
          "Een tafel met 2 stoelen waar ook het ontbijt geserveerd wordt",
          "Desgewenst kunnen wij 1 extra bed plaatsen, Kosten €25,-",
        ],
        German: [
          "Zusatzbett €25,- (Maximal 3 Personen)",
          "40m2",
          "Maximal 3 Personen",
          "Eigenem privaten Eingang",
          "Alles im Erdgeschoss",
          "Kostenlose Parkplätze",
          "Luxuriöses Kingsize-Boxspringbett von 160X200cm - Satinierte weiße Bettwäsche und Badtextilien von “De Witte Lietaer”",
          "Kostenloses superschnelles WLAN",
          "Privates Badezimmer mit Toilette, Waschbecken und großer begehbarer Dusche",
          "Zentralheizung",
          "Bettwäsche und Handtücher werden gestellt (Betten sind gemacht)",
          "Sitzgelegenheit zum Frühstücken",
          "Kühlschrank",
          "Wasserkocher",
          "Kostenlose Kaffee (Nespresso) und Tee-Einrichtungen",
          "Auf Wunsch servieren wir ein köstliches, frisches Frühstück im Zimmer für €20,- pro person",
          "Ein wunderschönes, geräumiges Zimmer mit großem Badezimmer und begehbarer Dusche im Erdgeschoss, mit einer Gesamtfläche von 40m2, inklusive: Garderobe, Flur, geräumiges WC, Abstellraum, separates Schlafzimmer mit einem komfortablen Doppelbett (160X200), angrenzendes Badezimmer mit großer begehbarer Dusche",
          "Ein Tisch mit 2 Stühlen, an dem auch das Frühstück serviert wird",
          "Auf Wunsch können wir ein zusätzliches Bett aufstellen, Kosten €25,-",
        ],
        Chinese: [
          "额外床铺 €25,-（最多3人）",
          "40平方米",
          "最多3人",
          "私人入口",
          "一切都在一楼",
          "免费停车",
          "豪华160X200厘米的特大号弹簧床 - “De Witte Lietaer”品牌的亚麻色床上用品和浴巾",
          "免费超快速WiFi",
          "带卫生间、洗手盆和大型步入式淋浴的私人浴室",
          "中央供暖",
          "提供床上用品和毛巾（床已铺好）",
          "用餐区",
          "冰箱",
          "热水壶",
          "免费咖啡（Nespresso）和茶设施",
          "如需，我们可以在房间内提供美味新鲜的早餐，每人€14.50,-",
          "一间宽敞明亮的客房，设有宽敞的浴室和步入式淋浴，位于一楼，总面积40平方米，包括：衣帽间、走廊、宽敞卫生间、储物柜、带舒适双人床（160X200）的独立卧室，带大型步入式淋浴的连接浴室",
          "一张桌子和两把椅子，可用于用餐",
          "根据需要，我们可以提供1张额外的床，费用€25,-",
        ],
        English: [
          "Extra bed €25,- (Max 3 persons)",
          "40m2",
          "Maximum 3 persons",
          "Private entrance",
          "All on the ground floor",
          "Free parking",
          "Luxury kingsize boxspring bed of 160X200cm - Satin white bedding and bath textiles from “De Witte Lietaer”",
          "Free super-fast WiFi",
          "Private bathroom with toilet, sink, and large walk-in shower",
          "Central heating",
          "Provided with bath and bed linen (towels, beds are made)",
          "Seating area to enjoy breakfast",
          "Refrigerator",
          "Kettle",
          "Free coffee (Nespresso) and tea facilities",
          "If desired, we can provide a delicious, fresh breakfast in the room for €20 per person",
          "A beautiful, spacious room with a large bathroom and walk-in shower on the ground floor, with a total area of 40m2 including: Wardrobe, Hall, spacious toilet, storage cupboard, separate bedroom with a comfortable double bed (160X200), ensuite bathroom with large walk-in shower",
          "A table with 2 chairs where breakfast is served",
          "If desired, we can place 1 extra bed, Cost €25,-",
        ],
        Spanish: [
          "Cama extra €25,- (Máximo 3 personas)",
          "40m2",
          "Máximo 3 personas",
          "Entrada privada",
          "Todo en la planta baja",
          "Aparcamiento gratuito",
          "Cama de muelles embolsados de lujo tamaño king de 160X200cm - Ropa de cama blanca de satén y textiles de baño de “De Witte Lietaer”",
          "Wifi súper rápido gratuito",
          "Baño privado con inodoro, lavabo y amplia ducha a ras de suelo",
          "Calefacción central",
          "Provisto de ropa de baño y cama (toallas, las camas están hechas)",
          "Zona de estar para disfrutar del desayuno",
          "Refrigerador",
          "Hervidor",
          "Café gratuito (Nespresso) y facilidades para hacer té",
          "Si lo desea, podemos proporcionar un delicioso y fresco desayuno en la habitación por €20l por persona",
          "Una hermosa y espaciosa habitación con un amplio baño y ducha a ras de suelo en la planta baja, con un área total de 40m2 que incluye: Armario, Vestíbulo, amplio inodoro, armario de almacenamiento, dormitorio separado con una cómoda cama doble (160X200), baño ensuite con amplia ducha a ras de suelo",
          "Una mesa con 2 sillas donde se sirve el desayuno",
          "Si lo desea, podemos colocar 1 cama extra, Costo €25,-",
        ],
      },
    },
  ],
  [
    {
      hero: "App. Villa Steenwyck",
      "2personen": 145,
      "3personen": 0,
      copy1: {
        Dutch:
          "Naast Boutique hotel Villa Steenwyck, bevind zich “Appartement Villa Steenwyck.",
        German:
          "Neben dem Boutique-Hotel Villa Steenwyck befindet sich das 'Appartement Villa Steenwyck.",
        Chinese: "在精品酒店维拉斯蒂恩维克别墅旁边，有“维拉斯蒂恩维克公寓。",
        English:
          "Next to Boutique hotel Villa Steenwyck, there is 'Apartment Villa Steenwyck'.",
        Spanish:
          "Al lado del hotel boutique Villa Steenwyck se encuentra el 'Apartamento Villa Steenwyck'.",
      },
      copy2: {
        Dutch:
          "Een volledig gelijkvloers ingericht appartement, op de begane grond, van 110m2 met daarin: Hal, Toilet, opbergkast, aparte slaapkamer met een heerlijk 2-persoons bed (160X200), ensuite badkamer met grote inloop douche. Een prachtige woonkamer met hoog, open, punt plafond, gecombineerde volledige ingerichte keuken met gezellige eetbar.",
        German:
          "Ein komplett ebenerdig eingerichtetes Apartment im Erdgeschoss mit 110m2, bestehend aus: Flur, Toilette, Abstellkammer, separates Schlafzimmer mit einem komfortablen Doppelbett (160X200), angrenzendem Badezimmer mit großer begehbarer Dusche. Ein wunderschönes Wohnzimmer mit hohem, offenen, spitzem Decke, kombinierte voll ausgestattete Küche mit gemütlicher Essbar.",
        Chinese:
          "一间完全位于一楼的110平方米单层公寓，内设有：门厅、卫生间、储藏柜、带舒适双人床（160X200）的独立卧室，带大型步入式淋浴的连接浴室。一个拥有高高的、敞开式、尖顶天花板的美丽客厅，配有舒适的吧台的完全配备的厨房。",
        English:
          "A fully furnished apartment on the ground floor of 110m2 containing: Hall, toilet, storage cupboard, separate bedroom with a lovely double bed (160X200), ensuite bathroom with large walk-in shower. A beautiful living room with high, open , pointed ceiling, combined fully equipped kitchen with cozy dining bar.",
        Spanish:
          "Un apartamento completamente amueblado en la planta baja de 110 m2 que contiene: vestíbulo, aseo, armario, dormitorio independiente con una preciosa cama doble (160X200) y cuarto de baño privado con gran ducha a ras de suelo. Un hermoso salón con techo alto, abierto y puntiagudo, cocina totalmente equipada combinada con una acogedora barra comedor.",
      },
      voorziening: {
        Dutch: [
          "Eigen privé opgang",
          "Alles op de begane grond en zonder drempels, rolstoel vriendelijk. Behalve het stoepje bij de voordeur.",
          "Luxe kingsize boxspring bed van 160X200cm",
          "Satijnen wit beddengoed en badtextiel van “De witte Lietaer”",
          "Luxe Philips Ambilight TV met Netflix en kabel TV",
          "Gratis super snelle wifi",
          "Privé badkamer met toilet,wastafel en grote inloopdouche",
          "Voorzien van Daikin Airconditioning",
          "Voorzien van bad linnen (handdoeken, gastendoekjes)",
          "Woonkamer met pelletkachel en Chesterfield inrichting",
          "Eigen privé terras met zitje",
          "Compleet ingerichte keuken met:",
          "Koelkast",
          "Borden,bestek, pannenset, glazen, kopjes",
          "5-pits gasfornuis",
          "Waterkoker",
          "Gratis koffie (Nespresso) en thee faciliteiten",
          "Eet bar met 4 bar krukken",
        ],
        German: [
          "Eigenem privaten Eingang",
          "Alles im Erdgeschoss und ohne Schwellen, rollstuhlgerecht. Mit Ausnahme der Stufe an der Haustür.",
          "Luxuriöses Kingsize-Boxspringbett von 160X200cm",
          "Satinierte weiße Bettwäsche und Badtextilien von “De Witte Lietaer”",
          "Luxuriöser Philips Ambilight Fernseher mit Netflix und Kabel-TV",
          "Kostenloses superschnelles WLAN",
          "Privates Badezimmer mit Toilette, Waschbecken und großer begehbarer Dusche",
          "Ausgestattet mit Daikin Klimaanlage",
          "Bettwäsche und Handtücher werden gestellt",
          "Wohnzimmer mit Pelletkamin und Chesterfield-Möbeln",
          "Eigenes privates Terrasse mit Sitzgelegenheit",
          "Voll ausgestattete Küche mit:",
          "Kühlschrank",
          "Geschirr, Besteck, Kochgeschirr, Gläser, Tassen",
          "5-flammiger Gasherd",
          "Wasserkocher",
          "Kostenlose Kaffee (Nespresso) und Tee-Einrichtungen",
          "Essbar mit 4 Barhockern",
        ],
        Chinese: [
          "独立私人入口",
          "一切都位于一楼，没有门槛，适合轮椅使用者。除了前门的台阶。",
          "豪华160X200厘米的特大号弹簧床",
          "“De Witte Lietaer”品牌的亚麻色床上用品和浴巾",
          "配备Netflix和有线电视的豪华Philips Ambilight电视",
          "免费超快速WiFi",
          "带卫生间、洗手盆和大型步入式淋浴的私人浴室",
          "配备Daikin空调",
          "提供床上用品和毛巾",
          "带木屑燃料炉和切斯特菲尔德装饰的客厅",
          "带座位的私人露台",
          "配备齐全的厨房，包括：",
          "冰箱",
          "盘子、餐具、锅具、玻璃杯、杯子",
          "5个燃气灶",
          "热水壶",
          "免费咖啡（Nespresso）和茶设施",
          "带4个吧台凳的餐吧",
        ],
        English: [
          "Private entrance",
          "All on the ground floor and without thresholds, wheelchair friendly. Except for the step at the front door.",
          "Luxury kingsize boxspring bed of 160X200cm",
          "Satin white bedding and bath textiles from “De Witte Lietaer”",
          "Luxury Philips Ambilight TV with Netflix and cable TV",
          "Free super-fast WiFi",
          "Private bathroom with toilet, sink, and large walk-in shower",
          "Equipped with Daikin Air conditioning",
          "Provided with bath linen (towels, guest towels)",
          "Living room with pellet stove and Chesterfield furnishings",
          "Private terrace with seating",
          "Fully equipped kitchen with:",
          "Refrigerator",
          "Plates, cutlery, cookware, glasses, cups",
          "5-burner gas stove",
          "Kettle",
          "Free coffee (Nespresso) and tea facilities",
          "Dining bar with 4 bar stools",
        ],
        Spanish: [
          "Entrada privada",
          "Todo en la planta baja y sin desniveles, accesible en silla de ruedas. Excepto por el escalón en la puerta principal.",
          "Cama de muelles embolsados de lujo tamaño king de 160X200cm",
          "Ropa de cama blanca de satén y textiles de baño de “De Witte Lietaer”",
          "Televisor Philips Ambilight de lujo con Netflix y televisión por cable",
          "Wifi súper rápido gratuito",
          "Baño privado con inodoro, lavabo y amplia ducha a ras de suelo",
          "Equipado con aire acondicionado Daikin",
          "Provisto de ropa de baño (toallas, toallas de invitados)",
          "Sala de estar con estufa de pellets y mobiliario Chesterfield",
          "Terraza privada con asientos",
          "Cocina totalmente equipada con:",
          "Refrigerador",
          "Platos, cubiertos, utensilios de cocina, vasos, tazas",
          "Placa de gas de 5 quemadores",
          "Hervidor",
          "Café gratuito (Nespresso) y facilidades para hacer té",
          "Barra de comedor con 4 taburetes",
        ],
      },
    },
  ],
];

export default kamers;
